import React, { useState } from "react";
import Header from "../../Layout/header";
import Footer from "../../Layout/footer";
import Innovate from "../../components/innovate";

function Index() {

  return (
  
        <div>
          <Header />
          <div className="grid grid-cols-12">
            <div className="col-span-11 w-[95vw] p-4 sm:p-0">
              <Innovate />
            </div>
            <div className="">
              <Footer />
            </div>
          </div>
        </div>
  );
}

export default Index;
