import React, { useState } from "react";
import { Card, Typography, Grid } from "@mui/material";
import "./header.css";
import logoImage from "../../assets/FinalLogo.png";
import headerIcon from "../../assets/headerIcon.png";
import circle from "../../assets/logoCircle.png";
import dots from "../../assets/logo_inside.png";
import modalClose from "../../assets/modalClose.png";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

function Header() {
  const [isOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="headerContainer">
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Link to="/" className="noDecoration">
            <img src={logoImage} className="h-28 sm:h-24 ml-0 sm:ml-16" alt="logo" />
          </Link>
        </Grid>
        {!isOpen ? (
          <Grid item xs={6} className="sideBarIcon" alignItems={"center"} style={{ textAlign: "right" }}>
            <div className="h-16 sm:h-16 mr-0 sm:mr-8 rotatingLogoContainer" onClick={openModal}>
                <img src={circle} alt="Outer Circle" className="circle h-16 sm:h-16"/>
                <img src={dots}  alt="Dots" className="dots h-8 sm:h-8"/>
            </div>

            {/* <img src={headerIcon} className="h-16 sm:h-16 mr-4 sm:mr-16" alt="sidebarIcon" onClick={openModal} /> */}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="text-2xl sm:text-4xl">
          {" "}
          <Grid item xs={12} className="sideBarIcon" style={{ textAlign: "right" }}>
            <img src={modalClose} className="h-16 sm:h-16 mr-4 sm:mr-12" onClick={closeModal} alt="closeSideBarIcon" />
          </Grid>
          <div className="flex flex-col gap-7 p-12 items-start">
            <Link to="/" className="noDecoration">
              <p className="modalItem">Home</p>
            </Link>
            <Link to="/projects" className="noDecoration">
              <p className="modalItem">Projects</p>
            </Link>
            <Link to="/events" className="noDecoration">
              <p className="modalItem">Events</p>
            </Link>
            <Link to="/aboutus" className="noDecoration">
              {" "}
              <p className="modalItem">About Us</p>
            </Link>
            <Link to="/team" className="noDecoration">
              {" "}
              <p className="modalItem">Team</p>
            </Link>
            <Link to="/partners" className="noDecoration">
              {" "}
              <p className="modalItem">Partners</p>
            </Link>
            <Link to="/contactUs" className="noDecoration">
              {" "}
              <p className="modalItem">Contact Us</p>
            </Link>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default Header;
