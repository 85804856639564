import React from "react";
import { Card, Typography, Grid } from "@mui/material";
import architechProfile from "../../assets/architechProfile.png";
import P1 from '../../assets/p1.png'
import P2 from '../../assets/p2.png'
import P3 from '../../assets/p3.png'
import P4 from '../../assets/p4.png'
import P5 from '../../assets/p5.png'
import P6 from '../../assets/p6.png'
import P7 from '../../assets/p7.png'
import P8 from '../../assets/p8.png'
import P9 from '../../assets/p9.png'
import P10 from '../../assets/p10.png'
import P11 from '../../assets/p11.png'
import P12 from '../../assets/p12.png'
import P13 from '../../assets/p13.png'
import P14 from '../../assets/p14.png'
import "./team.css";
import { easeIn, easeInOut, motion } from "framer-motion";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";

function Team() {
  const variants={
    hidden: {opacity: 0, y: 200},
    visible:{opacity: 1, y: 0 },
    default:{rotate: -90,
              translateY: 120,
              translateX: 100,},
    moveTo:{rotate: -90,
            translateY: 120,
            translateX: -200,
          }
  }
  const ref = useRef(null)
  const isInView = useInView(ref,{margin: "-50% 0px -50% 0px"})

  useEffect(() => {
  }, [isInView])


  return (
    <div className="pl-8 mb-36 sm:pl-16 pr-8 sm:pr-16 overflow-y-hidden">
      
      <Grid container item xs={9} className="mt-64 sm:mt-48" marginTop={"12em"}>
        <Grid container item rowSpacing={13} xs={12} justifyContent="center">
        
          <Grid container item md={12} rowSpacing={8} columnSpacing={2} >
            <Grid container item xs={12} justifyContent="center" spacing={2} >
              <Grid container item rowSpacing={2} columnSpacing={12} md={9} xs={12} order={{ md: 1 , lg: 1 ,xs:2}}
        
                    component={motion.div}
                    initial= {variants.hidden}
                    whileInView={variants.visible}
                    transition={{duration:1}}
              >
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer">
                          <img src={P1} alt="person" className="architechProfile" />
                        </div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">D.A.Dhanesh Chathuranga</h1>
                          Smart City Consultant | Chartered Architect
                          B.Arch(UoM)AIA(SL),
                          M.Eng (Smart city solutions) (HFT),Germany
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P2} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold"> A.M. Vikas Rao</h1> (India)
                          Smart City Consultant | Architect
                          B.Arch,
                          M.Eng (Smart city solutions) (HFT),Germany
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P3} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">Ashra M. Wickramathilaka</h1>
                          Urban Planner
                          St.Louis County Missouri,USA
                          </p>
                        </div>
                      </div>
                    </Grid>
        
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
                <Grid container item rowSpacing={2} columnSpacing={12} md={9} xs={12} order={{ md: 1 , lg: 1 ,xs:2}}
        
                      component={motion.div}
                      initial= {variants.hidden}
                      whileInView={variants.visible}
                      transition={{duration:1}}
                >
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P4} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">S.D.Gihan Malshan</h1>
                          Undergrad Architect - level 04 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P6} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">M.L.A.Pasan Ravinidu</h1>
                          Undergrad Architect - level 04 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P5}  alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                         <h1 className="font-semibold"> W.M.C. Ajanee</h1>
                          Undergrad Architect - level 03 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
              <Grid container item rowSpacing={2} columnSpacing={12} md={9} xs={12} order={{ md: 1 , lg: 1 ,xs:2}}
                  component={motion.div}
                  initial= {variants.hidden}
                  whileInView={variants.visible}
                  transition={{duration:1}}
              >
              <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P9} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                         <h1 className="font-semibold"> H.P Supun Shiwantha</h1>
                          Visual Arts Undergrad -
                          level 03 (UVPA) (specialized Product Design)
                          </p>
                        </div>
                      </div>
                    </Grid>
              <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer">
                          <img src={P8} alt="person" className="architechProfile" />
                        </div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold"> P.K.Pathum Gimhana</h1>
                            Undergrad Architect - level 02 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
        
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer">
                          <img src={P10} alt="person" className="architechProfile" />
                        </div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold"> K.B.Chami Amasha</h1>
                              Integrated Design Undergrad -
                              level 01 (UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
              <Grid container item rowSpacing={2} columnSpacing={12} md={9} xs={12} order={{ md: 1 , lg: 1 ,xs:2}}
                  component={motion.div}
                  initial= {variants.hidden}
                  whileInView={variants.visible}
                  transition={{duration:1}}
              >
              <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P11} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">A.A.H.P. Wijesundara</h1>
                          Undergrad Architect - level 01 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P13} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">W.S.A. Perera</h1>
                            Undergrad Architect - level 01 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P14} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">G.W. vishwa Nirmal</h1>
                            Undergrad Architect - level 02 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" colSpacing={2}>
              <Grid container item rowSpacing={12} columnSpacing={12} md={9} xs={12}  order={{ md: 1 , lg: 1 ,xs:2}}
              component={motion.div}
              initial= {variants.hidden}
              whileInView={variants.visible}
              transition={{duration:1}}
              ref={ref}
              >
              <Grid item md={4} xs={12} className="card" ref={ref}>
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P12} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">K.A.D. Bimsara Manudam</h1>
                          Undergrad Architect - level 01 B.Arch(UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="card">
                      <div className="memberContainer">
                        <div className="imgContainer"><img src={P7} alt="person" className="architechProfile" /></div>
                        <div className="nameContainer">
                          <p className="roleDescription">
                          <h1 className="font-semibold">Navodya Dhanushi</h1>
                          Graduate Architect - B.Arch (UoM)
                          </p>
                        </div>
                      </div>
                    </Grid>
        
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={1} className="teamTitleContainer" justifyContent={"right"}
          
          >
              <Grid item md={12} xs={12} className="text-xs sm:text-base" order={{ md: 2 , lg: 2 ,xs:1}}
                
                component={motion.div}
                initial={variants.default}
                animate={isInView ? "moveTo" : "default"}
                variants={variants}
                transition={{ duration: 0.5, ease:easeInOut}}
              >
                    <p className="subHead ">Project Team</p>
                </Grid>
          </Grid>
        
        </Grid>
        <Grid container item xs={3} spacing={0} className="teamContainer flex justify-end mt-8 sm:mt-8" style={{marginTop:"2em"}}>
          <Grid item xs={8.8}></Grid>
          <Grid item xs={3} className="titleContainer">
            <div className="titleStylesTeam">
              <div className="transparentBold"></div>
            </div>
            <div className="titleStylesTeam">
              <div className="redBoldTeam"></div>T
            </div>
            <div className="titleStylesTeam">
              <div className="transparentBold"></div>EAM
            </div>
          </Grid>
        </Grid>
      </Grid>
      
    </div>
  );
}

export default Team;
