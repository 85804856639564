import React from "react";
import Header from "../../Layout/header";
import Footer from "../../Layout/footer";
import Team from "../../components/team";

function index() {
  return (
    <div className="relative">
    <Header />
    <div className="grid grid-cols-12">
      <div className="col-span-11 w-[95vw]">
        <Team />
      </div>
      <div className="fixed right-0 top-24 sm:right-28 sm:top-24">
        <Footer />
      </div>
    </div>
  </div>
  );
}

export default index;
