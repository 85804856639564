import { Route, Routes, BrowserRouter } from "react-router-dom";
import AboutUs from "./pages/aboutUs";
import Innovate from "./pages/innovate/Index";
import Partners from "./pages/partners";
import Team from "./pages/team";
import Projects from "./pages/projects";
import Publications from "./pages/publications";
import ContactUs from "./pages/contactUs";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { useState,useEffect } from "react";
import { motion,AnimatePresence } from "framer-motion";
import React, { Suspense } from "react";
import Spinner from "./components/spinner/Spinner";

const LazyInnovate = React.lazy(() => import("./pages/innovate/Index"));
const LazyProject = React.lazy(() => import("./pages/projects"));
const LazyTeam = React.lazy(() => import("./pages/team"));
const LazyEvents = React.lazy(() => import("./pages/events"));

function App() {

  const text = ["Planners","Designers","Co-Creators","Consultants","Social Innovation Lab - Sri Lanka"];
  const [finished, setFinished] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    if (!finished && currentItemIndex < text.length) {
     
      const timer = setTimeout(() => {
        setCurrentItemIndex(currentItemIndex + 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else if (currentItemIndex === text.length && !finished) {
       setFinished(true);
       setInitialLoadComplete(true)
    }
  }, [currentItemIndex, finished, text.length]);

  return (
      <div>
      {finished && initialLoadComplete ? (
        <BrowserRouter>
        <Suspense fallback={<Spinner/>}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<LazyInnovate />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/team" element={<LazyTeam />} />
            <Route path="/projects" element={<LazyProject />} />
            <Route path="/events" element={<LazyEvents />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
          </ScrollToTop>
          </Suspense>
        </BrowserRouter>
      ) : (
        <div className="flex justify-center items-center text-xl sm:text-3xl font-semibold	 bg-background h-screen w-screen">
          <div className="flex flex-row w-screen gap-4">
            <div className="w-1/2 text-end	">We’re your _______</div>
            <AnimatePresence mode='wait'>
            <motion.div 
              className={`w-1/2 text-start text-base sm:text-3xl ${currentItemIndex===text.length-1?'text-textRed':'text-textGray'}`}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
              key={currentItemIndex}
            >
              {text[currentItemIndex]}
            </motion.div>
            </AnimatePresence>
          </div>
          
        </div>
      )
    }
    </div>
      
   

  );
}

export default App;
