import React, { useState } from 'react';
import './accordionHome.css';

import expandBtn from '../../assets/Internal.png'
import backBtn from '../../assets/Go Back.png'
import ha1 from '../../assets/ha1.png'
import ha2 from '../../assets/ha2.png'
import ha3 from '../../assets/ha3.png'
import collabImg1 from '../../assets/animPic1.png'
import collabImg2 from '../../assets/animPic2.png'
import collabImg3 from '../../assets/animPic3.png'
import { easeIn, easeInOut, motion } from 'framer-motion';
import { useMediaQuery } from "@mui/material";
import { Grid } from '@mui/material';

const AccordionHome = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSecondaryIndex, setActiveSecondaryIndex] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [hoveredIndex,setHoveredIndex] = useState(null)


  const handleBtnClick = (index) => {
    setActiveSecondaryIndex(index === activeSecondaryIndex ? null : index);
  };

  const handleImageClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  }; 

  const handleMouseEnter=(index)=>{
    setHoveredIndex(index)
  }
  const handleMouseLeave=(index)=>{
    setHoveredIndex(null)
  }
  

  return (
    
    <div className="flex flex-col sm:flex-row justify-center items-center gap-4 w-[85vw]">
      <div className={`accordionHome-item ${activeSecondaryIndex === 0 ? 'active' : ''} `}
       onMouseEnter={()=>handleMouseEnter(0)}
       onMouseLeave={()=>handleMouseLeave(0)}
       >
        <div
          className={`image-container ${activeIndex === 0 ? 'active hide' : ''} ${(hoveredIndex===2) && activeSecondaryIndex===null?'small':`${hoveredIndex===1 && activeSecondaryIndex===null?'mid':''}`}
          ${activeSecondaryIndex !== null ? 'opacity-40' : ''}`}
          onClick={(e) => handleImageClick(0)}
          
        >
          <img src= {ha1} alt="1" className="image" />

          <div className="image-overlayHome">
            <h1 className='text-4xl font-bold' >
              01
            </h1>
          </div>
        </div>
        <div className={` accordionHome-content sm:w-screen ${activeSecondaryIndex==null?"md:w-[90vw]":"md:w-[90vw]"}`}
          >
            
          <div className={`primaryContainer primaryContainerOne ${activeSecondaryIndex === 0 ? 'hide' : ''}`} >
          <img src={ha1} alt="card 1" className='card-bg' />
            <div className='overlay' onClick={() => handleImageClick(0)}>
            <div className='flex flex-col gap-4'>
                <Grid item container xs={12} className='text-white'>
                      <div className="title1HomeAcc">
                        <div className="redBoldHomeAcc" style={{marginLeft: '7.4vw' }}></div>COLL</div>
                      <div className="title2HomeAcc">
                        <div className="transparentBoldHomeAcc"></div>ABORATE
                      </div>
                </Grid>
                <p className='w-68 sm:w-96 '>
                  Our multidisciplinary approach brings together individuals with diverse expertise,
                  skill sets, and backgrounds, fostering a wider range of perspectives and impactful
                  solutions.
                </p>
                <img src={expandBtn} alt="expand button" className='expandBtn hover:cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(0);
                }}/>
              </div>
              <h2 className='text-5xl font-bold'>01</h2>
            </div>            
          </div>
          <div className={`h-full relative ${activeSecondaryIndex === 0 ? 'active' : 'hide'}`} onClick={()=> handleBtnClick(null)} onMouseLeave={(e)=>{e.stopPropagation();handleBtnClick(null)}}>
          <img src={backBtn} alt="expand button" className={`fixed bottom-20 left-28 expandBtn hover:cursor-pointer ${activeSecondaryIndex!==0?'hide':''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(0);
                }}/>
            <Grid container item xs={12} className='projectContainerHome'>
                <Grid container item xs={12}>
                    <Grid container item md={5} xs={12} className='h-40'>
                      <Grid container item xs={12} className='h-16'>
                          <div className="title1HomeAcc">
                            <div className="redBoldHomeAcc" style={{marginLeft: '7.4vw' }}></div>COLL</div>
                          <div className="title2HomeAcc">
                            <div className="transparentBoldHomeAcc"></div>ABORATE
                          </div>
                      </Grid>                      
                      <Grid container item xs={12} className='h-fit sm:h-16'>
                        <span className='text-2xl sm:text-4xl font-semibold mb-6'>Multidisciplinary approach</span>
                        <span className='text-justify w-68 text-lg sm:text-2xl'>                  
                        Bringing together specialists from various fields to build a diverse environment where ideas gather, mingle, and collaborate. This approach aids us in tackling challenging issues with imaginative and comprehensive solutions.
                        </span>
                      </Grid>
                    </Grid>
                    <Grid item md={7} xs={12} className='collabImgContainer mb-8'>
                        <img src={ha1} alt="pr1_img1" className='collabImg ml-0 sm:ml-40'/>
                        <img src={collabImg1} alt="pr1_img1" className='collabImg top-32 left-20 h-64 sm:top-64 sm:h-72 opacity-10	sm:opacity-100'/>
                    </Grid>
                </Grid>
              </Grid> 
              <Grid container item xs={12} className='projectContainerHome ' alignItems={"center"} >
                <Grid container item xs={12} spacing={2} className=''>
                    <Grid item md={6} xs={12} alignContent={"center"}>
                      
                    <h1 className='text-2xl sm:text-4xl font-semibold mb-6 '>Building capacity in communities!</h1>
                    <h1 className='text-justify text-lg sm:text-2xl'>                  
                                At the heart of our social innovation lab lies the commitment to 'Building Capacity in Communities.' We empower individuals by equipping them with the skills, knowledge, and resources to be proactive drivers of positive change. Our collaborative workshops, training, and access to expert networks, fosters self-reliance and amplify community-led initiatives, creating a sustainable impact from within”.
                    </h1>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <img src={collabImg2} alt="pr1_img1" className='projectImgContainer ml-40 h-64 opacity-30	sm:opacity-100'/>
                    </Grid>
                </Grid>
              </Grid> 
              <Grid container item xs={12} className='pt-0 projectContainerHome ' alignItems={"center"}>
                <Grid container item xs={12} spacing={2} className=''>
                    <Grid item md={6} xs={12} alignContent={"center"}>
                      
                    <h1 className='text-2xl sm:text-4xl font-semibold mb-6'>Meaningful acceleration</h1>
                    <h1 className='text-justify text-lg sm:text-2xl'>                 
                        Through strategic resource allocation, robust partnerships, and agile iteration, we successfully harnessed meaningful acceleration within our social innovation lab. This approach enabled us to propel our initiatives forward, rapidly translating ideas into actionable solutions and creating substantial positive change within our target communities.
                    </h1>
                    </Grid >
                    <Grid item md={6} xs={12}>
                        <img src={collabImg3} alt="collabImg3" className='projectImgContainer ml-0 sm:ml-20'/>
                    </Grid>
                    
                </Grid>
              </Grid> 
          </div>
        </div>
      </div>

      <div className={`accordionHome-item ${activeSecondaryIndex === 1 ? 'active' : ''}`}
            onMouseEnter={()=>handleMouseEnter(1)}
            onMouseLeave={()=>handleMouseLeave(1)}
      >
          <div
            className={`image-container ${activeIndex === 1 ? 'active hide' : ''} ${(hoveredIndex===0 || hoveredIndex===2) && activeSecondaryIndex===null?'mid':''}
            ${activeSecondaryIndex !== null ? 'opacity-40' : ''}`}
            onClick={() => handleImageClick(1)}
          >
            <img src={ha2} alt="2" className="image" />
            <div className="image-overlayHome">
              <h1 className='text-4xl font-bold' >
                02
              </h1>
            </div>
          </div>
        <motion.div className={`accordionHome-content sm:w-screen ${activeSecondaryIndex!==1?"md:w-[50vw]":"md:w-[90vw]"}`}
          initial={{width:0}}
          animate={{width:`${isSmallScreen?"100%": activeSecondaryIndex!==1?"90vw":"90vw"}`}}
          transition={{duration:0.4, ease:easeInOut}}
          >
          <div className={`primaryContainer primaryContainerOne ${activeSecondaryIndex === 1 ? 'hide' : ''}`}  >
          <img src={ha2} alt="card 1" className='card-bg' />
            <div className='overlay' onClick={() => handleImageClick(1)}>
            <div className='flex flex-col gap-4'>
                <Grid item xs={12} className='flex text-white'>
                  <div className="title1HomeAcc">
                    <div className="redBoldHomeAcc" style={{marginLeft: '0vw' }}></div>
                    <div>I</div>
                  </div>
                  <div className="title2HomeAcc">
                        <div className="transparentBoldHomeAcc"></div>NNOVATE
                  </div>
                </Grid>
                <p className='w-68 sm:w-96 '>
                We push boundaries, constantly seeking new ideas and designs that challenge conventional approaches
                </p>
                <img src={expandBtn} alt="expand button" className='expandBtn hover:cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(1);
                }}/>
              </div>
              <h2 className='text-5xl font-bold'>02</h2>
            </div>            
          </div>
          <div className={`h-full ${activeSecondaryIndex === 1 ? 'active' : 'hide'}`} onClick={() => handleBtnClick(null)}>
          <Grid container item xs={12} className='projectContainerHome' alignItems={"center"} onMouseLeave={(e)=>{e.stopPropagation();handleBtnClick(null)}}>
                <Grid container item xs={12} spacing={2} className=''>
                    <Grid item md={7} xs={12} alignContent={"center"}>
                    <Grid item xs={12} className='flex'>
                    <div className="title1HomeAcc">
                      <div className="redBoldHomeAcc" style={{marginLeft: '0vw' }}></div>
                      <div>I</div>
                    </div>
                    <div className="title2HomeAcc">
                          <div className="transparentBoldHomeAcc"></div>NNOVATE
                    </div>
                  </Grid>
                      
                    <h1 className='text-2xl sm:text-4xl font-semibold mt-1'>Driving Solutions through Innovation</h1>
                    <h1 className='mt-4 text-xl text-justify'>               

                    Innovative solutions play a vital role in the problem-solving process.
                    We believe that tackling complex challenges requires thinking beyond conventional 
                    boundaries, and that's where innovation comes into play. By fostering a culture of 
                    creativity and encouraging novel approaches, we empower our team to develop cutting-edge 
                    solutions that can address the most pressing issues. Whether it's in scientific
                      research, technological advancements, or real-world applications, the infusion of 
                      innovative ideas not only enhances the effectiveness of our problem-solving endeavors
                      but also propels us towards meaningful and sustainable outcomes.
                    </h1>
                    </Grid>
                    <Grid item md={5} xs={12} className='h-fit'>
                        <img src={ha2} alt="pr1_img1" className='projectImgContainer h-fit'/>
                    </Grid>
                    
                </Grid>
                <img src={backBtn} alt="expand button" className='expandBtn hover:cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(0);
                }}/>
              </Grid> 
          </div>
        </motion.div>
      </div>

      <div className={`accordionHome-item ${activeSecondaryIndex === 2 ? 'active' : ''}`}
            onMouseEnter={()=>handleMouseEnter(2)}
            onMouseLeave={()=>handleMouseLeave(2)}
      >
        <div
          className={`image-container ${(hoveredIndex===0 || hoveredIndex===1) && activeSecondaryIndex===null?'small':''}
          ${activeSecondaryIndex !== null ? 'opacity-40' : ''}`}
          onClick={() => handleImageClick(2)}
        >
          <img src={ha3} alt="3" className="image"  />
          <div className="image-overlayHome">
            <h1 className='text-4xl font-bold' >
              03
            </h1>
          </div>
        </div>
        <motion.div className={`accordionHome-content sm:w-screen `}
            initial={{width:0}}
            animate={{width:`${isSmallScreen?"100%": activeSecondaryIndex!==2?"90vw":"90vw"}`}}
            transition={{duration:0.4, ease:easeInOut}}
          >
          <div  className={`primaryContainer primaryContainerOne ${activeSecondaryIndex === 2 ? 'hide' : ''}`}>
            <img src={ha3} alt="card 1" className='card-bg' />
            <div className='overlay' onClick={() => handleImageClick(2)}>
            <div className='flex flex-col gap-4'>
                <Grid item xs={12} className='flex text-white'>
                      <div className="title1HomeAcc">
                        <div className="redBoldHomeAcc"  style={{marginLeft: '3vw' }}></div>
                            <div>CR</div>
                      </div>
                      <div className="title2HomeAcc">
                        <div className="transparentBoldHomeAcc"></div>EATE
                      </div>
                    </Grid>
                <p className='w-68 sm:w-96 '>
                We transform vision into reality, crafting unique and inspiring solutions that leave a 
                lasting impact on individuals and communities.
                </p>
                <img src={expandBtn} alt="expand button" className='expandBtn hover:cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(2);
                }}/>
              </div>
              <h2 className='text-5xl font-bold'>03</h2>
            </div>            
          </div>
          <div className={`h-full ${activeSecondaryIndex === 2 ? 'active' : 'hide'}`} onClick={() => handleBtnClick(null)}>
            <Grid container item xs={12} className='projectContainerHome' alignItems={"center"} onMouseLeave={(e)=>{e.stopPropagation();handleBtnClick(null)}}>
               <Grid container item xs={12} spacing={2} className=''>
                  <Grid item md={6} xs={12} alignContent={"center"}>
                    <Grid item xs={12} className='flex'>
                      <div className="title1HomeAcc">
                        <div className="redBoldHomeAcc"  style={{marginLeft: '3vw' }}></div>
                            <div>CR</div>
                      </div>
                      <div className="title2HomeAcc">
                        <div className="transparentBoldHomeAcc"></div>EATE
                      </div>
                    </Grid>
                  <h1 className='text-2xl sm:text-4xl font-semibold mt-1'>Empowering Change Through Creation</h1>
                  <h1 className='mt-4 text-xl text-justify'>
                    At the core of our philosophy lies the belief that creation is the
                     ultimate manifestation of our solutions.  This perspective extends to
                      our practical implementations, where we diligently consider the real-world
                      implications. By doing so, we aim to construct not just solutions, but vehicles
                      for social innovation, contributing to the greater good and fostering
                      positive change within our communities.
                  </h1>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <img src={ha3} alt="pr1_img1" className='projectImgContainer'/>
                      
                  </Grid>
              </Grid>
              <img src={backBtn} alt="expand button" className='expandBtn hover:cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBtnClick(1);
                }}/>
            </Grid>         
          </div>
        </motion.div>
      </div>
    </div>


  );
};

export default AccordionHome;
