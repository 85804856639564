import React from "react";
import Header from "../../Layout/header";
import Footer from "../../Layout/footer";
import ContactUs from "../../components/contactUs";

function index() {
  return (
    <div>
    <Header />
    <div className="grid grid-cols-12">
      <div className="col-span-11 w-[95vw]">
        <ContactUs />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  </div>
  );
}

export default index;
