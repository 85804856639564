import React, { useState,useEffect} from 'react';
import './accordion.css';
import pr1 from "../../assets/pr1.png";
import pr2 from "../../assets/pr2.png";
import pr3 from "../../assets/pr3.png";
import pr1_img1 from "../../assets/project_one_img1.png";
import pr1_img2 from "../../assets/project_one_img2.png";
import pr1_img3 from "../../assets/project_one_img3.png";
import pr1_img4 from "../../assets/project_one_img4.png";
import pr1_img5 from "../../assets/project_one_img5.png";
import pr1_img6 from "../../assets/project_one_img6.png";
import pr1_img7 from "../../assets/project_one_img7.png";
import pr1_img8 from "../../assets/project_one_img8.png";
import pr1_img9 from "../../assets/project_one_img9.png";
import pr1_img10 from "../../assets/project_one_img10.png";
import pr1_img11 from "../../assets/project_one_img11.png";
import pr1_img12 from "../../assets/project_one_img12.png";
import pr1_img13 from "../../assets/project_one_img13.png";
import pr2_img1 from "../../assets/project_two_img1.png";
import pr2_img2 from "../../assets/project_two_img2.png";
import pr2_img3 from "../../assets/project_two_img3.png";
import pr2_img4 from "../../assets/project_two_img4.png";
import pr2_img5 from "../../assets/project_two_img5.png";
import pr3_img1 from "../../assets/project_3_img1.png";
import pr3_img2 from "../../assets/project_3_img2.png";
import pr3_img3 from "../../assets/project_3_img3.png";
import pr3_img4 from "../../assets/project_3_img4.png";
import pr3_img5 from "../../assets/project_3_img5.png";
import pr3_img6 from "../../assets/project_3_img6.png";
import backBtn from '../../assets/Go Back.png'

import { Grid } from '@mui/material';
const Accordion = ({onClick,isOpen}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleImageClick = (index) => {

    setActiveIndex(index === activeIndex ? null : index);
    
  };
  useEffect(() => {
    onClick(activeIndex);
  }, [activeIndex, onClick])

  return (
    <div className={`flex accordionContainer flex-col sm:flex-row justify-center z-0 ${activeIndex !== null ? 'gap-4' : 'gap-14'}`}>
      <div className={`accordion-item ${activeIndex === 0 ? 'active' : ''}`}>
        <div
          className={`image-containerPro ${activeIndex === 0 ? 'hide' : `${isOpen? 'h-[80vh]' : 'h-[70vh]'} `}`}
          onClick={() => handleImageClick(0)}
        >
          <img src= {pr1} alt="1" className={`image ${activeIndex === 0 || activeIndex ===null ? '' :'rounded-3xl opacity-40 '}`} />

          <div className={`image-overlay ${activeIndex === 0 || activeIndex ===null? '' :'rounded-3xl '}`}>
            <p className={`${activeIndex !== null ? 'hide' : ''}`}>
              BUILDING A BETTER FUTURE
            </p>
          </div>
        </div>
        <div className="relative accordion-content sm:w-screen md:w-[80vw]" onClick={() => handleImageClick(0)}>
        <Grid item xs={12} className='sticky top-0 pt-10 pr-20'>
                  <Grid item xs={12} className="flex justify-end">
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>PRO
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="redBoldProjectAcc"></div>J
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>ECT - Community Centre
                    </div>
                  </Grid>
        </Grid> 
        <img src={backBtn} alt="expand button" className='fixed top-[38em] left-12 sm:left-20 expandBtnPro hover:cursor-pointer'/>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36' spacing={6} marginBottom={"6em"}>
            <Grid item xs={12}>
              <h2 className='font-bold text-xs sm:text-[33px] mb-2 sm:mb-4'>BUILDING A BETTER FUTURE:</h2> 
              {/* <h2 className='font-bold text-xs sm:text-[33px]'>A PROPOSED COMMUNITY CENTER</h2> */}
            </Grid>
            <Grid container item xs={12} spacing={5}>
                <Grid item md={5} xs={12}>
                  <p className='projectDescription text-sm sm:text-base'>The design philosophy centers on community engagement and the development of innovative construction systems through social collaboration. This project is conceived as an experimental and research initiative, employing recycled and readily available materials, particularly pallets. The design process emphasizes "learning by doing" as a pivotal objective. By actively involving the community, sustainable materials are utilized to construct a space that caters to their specific needs.
The initial phase focuses on establishing the building orientation and service offices for four government officials, forming the foundational elements of the design. Subsequently, the second phase will involve the creation of a community hall. Throughout the design stage, local communities play an integral role in the decision-making process.</p>
                </Grid>
                <Grid item md={7} xs={12} className='grid grid-cols-2 items-center mr-8'>
                    <img src={pr1_img1} alt="pr1_img1" className='projectImgContainer'/>
                    <img src={pr1_img2} alt="pr1_img2" className='projectImgContainer'/>
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-20' marginBottom={"6em"}>
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>CAP(Community Action Plan) work shop 
</h2> 
            </Grid>
            <Grid container item xs={12} spacing={5}>
                <Grid item md={6} xs={12} >
                  <p className='projectDescription text-sm sm:text-base '>
                  The inaugural community workshop took place at a residence within the local community, drawing the active participation of approximately 50 individuals. Primarily serving as an initial exploration of the area, this workshop played a crucial role in establishing a robust connection between our team and the villagers. Results derived from the Community Action Plan (CAP) workshop conducted in the "Ihala Vitiyala community" highlighted the following key findings:
1.The pressing need for a library emerged as a central theme, particularly in conjunction with the proposed construction of the community hall and other government facilities.
2. A recognized necessity for product development within the community surfaced, focusing on key industries such as jewelry making, Kohila cultivation, and the production of ornamental artificial flowers. This insight underscores the potential for enhancing economic activities aligned with these core sectors.</p>
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={pr1_img3} alt="pr1_img3"/>
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8'>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={3} xs={12}>
                <p className='projectDescription text-sm sm:text-base '>The  project embodies a sustainable ethos by utilizing recycled and sustainable materials. What sets this initiative apart is the active involvement of the community in the construction process. Beyond just the end result, the journey involves collaborative efforts, ensuring that the building not only meets environmental standards but also reflects the shared values and engagement of the community members.
Through the use of Eco-friendly materials and a participatory construction approach, this project serves as a testament to the power of community-driven sustainability in shaping the built environment.</p>
                </Grid>
                <Grid item md={9} xs={12}>
                  <div className='flex flex-row'>
                    <div>
                    <img src={pr1_img4} alt="pr1_img2"/>
                    <img src={pr1_img7} alt="pr1_img2"/>
                    <img src={pr1_img6} alt="pr1_img2"/>
                    </div>
                    <div>
                    <img src={pr1_img5} alt="pr1_img2"/>
                    </div>
                  </div>
                   
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8' spacing={6} >
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>“Learnings by Doing “
            </h2> 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={5} xs={12}>
                <p className='projectDescription text-sm sm:text-base '>At the heart of the project is the principle of learning by doing. Departing from traditional methods, this initiative places a strong emphasis on hands-on experience and active community participation. Community members are not just passive recipients of the built environment; they actively contribute to its creation. 
Through workshops, skill-sharing sessions, and collaborative construction efforts, individuals acquire practical skills in architecture and design, directly influencing their surroundings. This approach instills a sense of agency, empowerment, and a profound connection to the spaces they help bring to life. 
Learning by doing in community architecture transcends theoretical knowledge, making a meaningful and sustainable impact on both the built environment and the individuals involved in its creation.
</p>
                </Grid>
                <Grid item md={7} xs={12}>
                  <div className='flex flex-row gap-3'>
                    <div>
                    <img src={pr1_img8} alt="pr1_img2"/>
                    <div className="flex gap-3">
                      <img src={pr1_img9} alt="pr1_img2"/>
                      <img src={pr1_img10} alt="pr1_img2"/>
                    </div>
                    </div>
                    <div>
                    <img src={pr1_img11} alt="pr1_img2"/>
                    </div>
                  </div>
                   
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8' spacing={6}>
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>Product design 
            </h2> 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={6} xs={12}>
                <p className='projectDescription text-sm sm:text-base '>In Vitiyala village, located in the Kamburupitiya area of the Matara district in Sri Lanka, families primarily engage in farming, with a notable focus on Spiny Lasia cultivation. Traditionally, locals earn income by cultivating, cleaning, and selling Spiny Lasia tubers in the market. The proposed initiative aims to enhance their income by introducing new products derived from Spiny Lasia cultivation. This includes introducing unique hand-made paper designs under the brand SIL. SL, collaborating with local handicrafts. The proposal also extends to introducing Spiny Lasia-related products in the food production sector, contributing to the community's economic growth and market diversification.</p>
                <img src={pr1_img12} alt="pr1_img2"/>
                </Grid>
                <Grid item md={6} xs={12}>
         
                    
                      <img src={pr1_img13} alt="pr1_img2"/>
                 
                   
                </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={`accordion-item ${activeIndex === 1 ? 'active sm:w-[100vw]' : ''}`}>
        <div
          className={`image-containerPro ${activeIndex === 1 ? 'active hide' : `${isOpen? 'h-[80vh]' : 'h-[70vh]'} `}`}
          onClick={() => handleImageClick(1)}
        >
          <img src={pr2_img2} alt="2" className={`image ${activeIndex === 1 || activeIndex ===null ? '' :'rounded-3xl opacity-40'}`} />
          <div className={`image-overlay ${activeIndex === 1 || activeIndex ===null ? '' :'rounded-3xl '}`}>
            <p className={`${activeIndex !== null ? 'hide' : ''}`}>BRIDGING TRADITION <br/>&<br/> TECHNOLOGY</p>
          </div>
        </div>
        <div className="relative accordion-content sm:w-screen md:w-[80vw]" onClick={() => handleImageClick(1)}>
        <Grid item xs={12} className='sticky top-0 pt-10 pr-20'>
                  <Grid item xs={12} className="flex justify-end">
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>PRO
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="redBoldProjectAcc"></div>J
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>ECT - Community Tech Market 
                    </div>
                  </Grid>
                  <p className='font-lg text-right'>@Maligawatta, Colombo ,Sri Lanka 2023</p>
        </Grid> 
        <img src={backBtn} alt="expand button" className='fixed top-[40em] left-36 expandBtnPro hover:cursor-pointer'/>
        <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36' marginBottom={"6em"}>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={5}>
                  
                    <p className='projectDescription text-sm sm:text-base'>The Social Innovation Lab Sri Lanka (SIL.SL) is launching a project to revitalize the market street of Maligawatta ,Colombo . This initiative encompasses the development of an innovative structure designed to enhance functionality, improve visual appeal, and foster the overall well-being of the community.market </p>
                  <div className="flex w-full">  
                  <div className="w-full">
                  <img src={pr2_img1} alt="pr2_img1" className='object-cover projectImgContainer sm:p-6'/>
                  </div>
                    
                    <div className="w-full">
                    <img src={pr2_img2} alt="pr2_img2" className='object-contain sm:p-6'/>
                    </div>
                    
                  </div>
                
                </Grid>
                <Grid item xs={12} md={7} justifyContent='right' alignItems={"right"}>
                    <img src={pr2_img3} alt="pr2_img3" className='w-full'/>
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36' spacing={4} marginBottom={"6em"}>
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>Market Layer
            </h2> 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <div className="flex flex-col gap-6">
                  <img src={pr2_img4} alt="pr1_img1" className='projectImgContainer'/>
                  <p className='projectDescription text-sm sm:text-base '>Every morning, the streets of this community buzz with a vibrant market, bustling with daily selling activities. Fridays hold particular importance for the Muslim residents, with market activities concluding by 11:30 AM.  On weekdays, elder members actively participate in street vending. The community's overall education level is modest, shaped by cultural norms. While a few students aim for higher education, the majority opt to join and sustain their family enterprises upon completing their schooling.</p>
                </div>

                 
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className='projectContainer p-4 sm:p-8'>
            <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>SWOT  Analysis</h2> 
            </Grid>
            <Grid container item xs={12} md={6} >
                <p className='projectDescription text-sm sm:text-base '>People organize the space for their convenience, emphasizing minimal comfort during the fair. However, this setup becomes problematic during rainy conditions, as there is no suitable shelter or roofing. Consequently, both the vendors and their merchandise are exposed to the rain, lacking sufficient protection.
In addition  the lack of appropriate infrastructure to ensure the optimal condition of their selling. Currently, use small wooden or plastic boxes for packaging. However, an unintended consequence of this method is the insufficient security of these containers after business hours, elevating the risk of theft. Additionally, this practice poses challenges in maintaining the quality of vegetables and fruits, affecting their overall marketability.</p>
          
            </Grid>
            <Grid container item xs={12} md={6}>
                <img src={pr2_img5} alt="pr1_img2"/>
          
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={`accordion-item ${activeIndex === 2 ? 'active' : ''}`}>
        <div
          className={`image-containerPro ${activeIndex === 2 ? 'active hide' : ` ${isOpen? 'h-[80vh]' : 'h-[70vh]'} `}`}
          onClick={() => handleImageClick(2)}
        >
          <img src={pr3_img1} alt="3" className={`image ${activeIndex === 2 || activeIndex ===null ? '' :'rounded-3xl opacity-40'}`}  />
          <div className={`image-overlay ${activeIndex === 2 || activeIndex ===null ? '' :'rounded-3xl '}`}>
            <p className={`${activeIndex !== null ? 'hide' : ''}`}>GOLDEN CARE: EMPOWERING SENIOR SUPPORT</p>
          </div>
        </div>
        <div className="relative accordion-content sm:w-screen md:w-[80vw]" onClick={() => handleImageClick(2)}
        >
          <Grid item xs={12} className='sticky top-0 pt-10 pr-20'>
                  <Grid item xs={12} className="flex justify-end">
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>PRO
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="redBoldProjectAcc"></div>J
                    </div>
                    <div className="titleStylesProjectAcc">
                      <div className="transparentBold"></div>ECT - Empowering senior support
                    </div>
                  </Grid>
        </Grid> 
        <img src={backBtn} alt="expand button" className='fixed top-[40em] left-48 expandBtnPro hover:cursor-pointer'/>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36'marginBottom={"9em"}>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={3} xs={12}>
                <img src={pr3_img1} alt="pr1_img1" className='projectImgContainer'/>
                </Grid>
                <Grid item md={9} xs={12}>
                    <img src={pr3_img2} alt="pr1_img1" className='projectImgContainer'/>
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36'spacing={4}>
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>Design Approach 
            </h2> 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={4} xs={12}>
                <img src={pr3_img3} alt="pr1_img4"/>
                </Grid>
                <Grid item md={8} xs={12}>
                    <img src={pr3_img4} alt="pr1_img4"/>
                </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className='projectContainer p-4 sm:p-8 mb-4 sm:mb-36' spacing={4}>
          <Grid item xs={12}>
              <h2 className='font-normal text-xs sm:text-[30px] mb-2 sm:mb-4'>Conceptual Design 
            </h2> 
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item md={6} xs={12}>
                <img src={pr3_img5} alt="pr1_img4"/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={pr3_img6} alt="pr1_img4"/>
                </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
