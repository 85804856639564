import React, { useRef, useState } from "react";
import Stack from "@mui/material/Stack";

import emailjs from "@emailjs/browser";
import { Card, Typography, Grid } from "@mui/material";
import "./contactUs.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Snackbar, Alert } from "@mui/material";
import Modal from '@mui/material/Modal';
import modalClose from "../../assets/modalClose.png";
import { Margin } from "@mui/icons-material";
import Facebook from "../../assets/Facebook.svg";
import Instagram from "../../assets/Instagram.svg";
import X from "../../assets/TwitterX.svg";
import LinkedIn from "../../assets/LinkedIn.svg";

const Index = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      // .sendForm(
      //   "service_vcmqwcv",
      //   "template_sezfldh",
      //   form.current, // Here you're passing the ref
      //   "gIjapUeMuXEDqoh7l"
      // )
      .sendForm(
        "service_rmutbh7",
        "template_v8dg4zi",
       form.current, // Here you're passing the ref
        "O4_I__NqbJWI9OAGv"
      )
      .then(
        (result) => {
          handleClose();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [email, setEmail] = useState("");
  // const [inputValue2, setInputValue2] = useState("");
  // const [inputValue3, setInputValue3] = useState("");
  // const [open, setOpen] = React.useState(false);
  // const [openError, setOpenError] = React.useState(false);

  const handleInputChange1 = (value) => {
    setEmail(value);
    console.log(email);
  };
  // const handleInputChange2 = (event) => {
  //   setInputValue2(event.target.value);
  // };
  // const handleInputChange3 = (event) => {
  //   setInputValue3(event.target.value);
  // };
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="success">Message sent successfully</Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="error">Message sent failed!</Alert>
      </Snackbar> */}
      <div className="pl-8 sm:pl-16 pr-8 sm:pr-16">
        <Grid item container justifyContent="center" marginBottom={"4em"}>
          <Grid item xs={12} className="flex justify-end" >
            <div className="titleStylesContact">
              <div className="transparentBold"></div>C
            </div>
            <div className="titleStylesContact">
              {" "}
              <div className="redBoldContact" ></div>O
            </div>
            <div className="titleStylesContact">
              <div className="transparentBold"></div>NNECT WITH US
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12} className="detailsContainer mt-4" justifyContent="center">
          <Grid item md={6} xs={12}>
            <div className="contactInfo">
              <h2 className="font-bold text-2xl sm:text-4xl mt-4 sm:mt-0"> CONTACT INFO</h2>
              <div className="contactField">
                <h3 className="font-bold text-xl">Address:</h3>
                <p className="font-light">SIL.SL ,NO/124,Rathnapura Road, Horana,12400,<br/> Sri Lanka</p>
              </div>
              <div className="contactField">
                <h3 className="font-bold text-xl">Phone:</h3>
                <p className="font-light">(+49)176 433 946 74<br/>(+94) 778370884</p>
              </div>
              <div className="contactField">
                <h3 className="font-bold text-xl">Email:</h3>
                <p className="font-light">info@silab-sl.com</p>
              </div>
              <div className="contactField">
                <h3 className="font-bold text-xl">FaceBook:</h3>
                <p className="font-light">Social Innovation Lab. SL</p>
              </div>
              <div className="contactField">
                <h3 className="font-bold text-xl">Linkedin:</h3>
                <p className="font-light">Social Innovation Lab Sri Lanka</p>
              </div>              
              <div className="contactField">
                <h3 className="font-bold text-xl">Instagram:</h3>
                <p className="font-light">silab.sl</p>
              </div>              
              <div className="contactField">
                <h3 className="font-bold text-xl">Twitter:</h3>
                <p className="font-light">silab_sl</p>
              </div>              
            </div>
          </Grid>
          <Grid item md={1} xs={12} className="flex justify-center">
            <div className="w-full sm:w-px h-px sm:h-96 bg-black my-4 sm:my-0"></div>
          </Grid>
          <Grid item md={5} xs={12} gap={5} >
              <div className="flex flex-col gap-4 justify-start items-center sm:items-start mb-4 sm:mb-0">
                <div className="flex flex-col gap-4 h-fit w-fit items-center sm:items-start ">
                  <span className="text-xl font-bold">Social Media</span>
                  <div className="flex items-center">
                    <a href="http://linkedin.com/in/sil-sl" target="blank"><img src={LinkedIn} alt="linkedin_icon" /></a>
                    <a href="https://www.instagram.com/silab.sl/" target="blank"><img src={Instagram} alt="instagram_icon" /></a>
                    <a href="https://web.facebook.com/profile.php?id=100092173968884&_rdc=1&_rdr" target="blank"><img src={Facebook} alt="facebook_icon" /></a>
                    <a href="https://twitter.com/silab_sl" target="blank"><img src={X} alt="x_icon" /></a>
                  </div>
                </div>
                <div className="flex justify-start">
                  <button className="subBtn bg-textRed text-white rounded-full pl-4 pr-4 pt-2 pb-2 text-base w-24" onClick={handleOpen}>Subscribe</button>
                </div>
              </div>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex items-center justify-center h-screen">
            <div className=" toast flex flex-col items-center justify-center h-96 rounded-3xl pl-4 pr-4 pb-4 bg-gray"
            >
          <div className="flex justify-end w-full">
            <img src={modalClose} className="h-16 sm:h-20 mr-4 mb-8" onClick={handleClose} alt="closeSideBarIcon" />
          </div>
              <div className="flex flex-col items-center justify-center h-60">
                <h1 className="font-normal text-5xl">Newsletter</h1>
                <Grid item xs={12} style={{ textAlign: "center", marginBottom: "2em",marginRight: "6em"}}>
                  <div className="input-container">
                  <form ref={form} onSubmit={sendEmail}>
                    <input
                      type="text"
                      name="email" 
                      className="subInput text-center "
                      placeholder="Enter Your Email"
                      onChange={(e) => handleInputChange1(e.target.value)}
                      />{" "}
                     
                        <button className="subBtnL" type="submit" ref={form}>
                          <p className="text-center">SUBSCRIBE</p>
                        </button>
                  </form>
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Index;
