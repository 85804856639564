import React from "react";
import "./innovate.css";
import Footer from "../../Layout/footer";
import AccordionHome from "./AccordionHome";

function Index() {
 
  return (
    <div className="flex justify-center innovateContainer mx-8 pl-4 pb-4 sm:pl-12 sm:pb-6 z-10">
      <AccordionHome/>  
      {/* <Footer className='w-12 rotate-90 origin-top-left' />      */}
    </div>
  );
}

export default Index;
