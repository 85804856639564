import React from 'react'

function Spinner() {
  return (
    <div className="simple-spinner">
        <span></span>
    </div>
  )
}

export default Spinner