import React from "react";
import "./about.css";
import { Card, Typography, Grid } from "@mui/material";

function aboutUs() {
  return (
    <div className="h-full sm:h-[75vh] pl-8 sm:pl-16 pr-8 sm:pr-16">
     
        <Grid item md={5} xs={12} className="flex justify-end mb-4 sm:mb-0" order={{ md: 1 , lg: 2 ,xs:1}}>
            <div className="titleStylesAbout">
              <div className="transparentBold"></div>ABO
            </div>
            <div className="titleStylesAbout">
              <div className="redBoldAbout"></div>U
            </div>
            <div className="titleStylesAbout">
              <div className="transparentBold"></div>T US
            </div>
          </Grid>
      <Grid item xs={2} order={{ md: 2, lg: 1 ,xs:2}} className="w-full sm:w-2/3 pl-0 sm:pl-12">
          <h1 className="font-bold text-lg mb-4">"Welcome to SIL.SL: Pioneering Social Innovation in Sri Lanka and Beyond” </h1>
          <p className="bodyContentTxt text-lg">
          
          Step into SIL.SL, a prominent interdisciplinary research lab at the forefront of social innovation in Sri Lanka. Founded in 2022 by visionary architects and urban planners Dhanesh Chathuranga from Sri Lanka and Vikas Rao from India, our lab thrives on collaboration, partnering with local and global experts across diverse projects. At SIL.SL, we're dedicated to elevating social initiatives, refining processes, and empowering passionate changemakers through innovation. Our mission? To spark profound social change not just in the global south, but on a global scale. We offer unwavering support, propelling social innovators and ventures from inception to impactful outcomes. In a world woven with complex societal, economic, and environmental threads, we believe in crafting fresh solutions. Embracing new perspectives and strategic collaborations is vital. SIL.SL stands by inventive ventures driven by purpose and pioneering tech that breaks barriers. Our holistic approach involves architects, urban planners, social scientists, software engineers, and community collaborations, all converging to drive social innovation. Join us at the Social Innovation Lab as we diligently cultivate a thriving world, where social initiatives and ventures ripple positive effects across communities and the planet."
         </p>
      </Grid>
        
      
    </div>
  );
}

export default aboutUs;
