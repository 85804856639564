import React from "react";
import { Card, Typography, Grid } from "@mui/material";
import "./partners.css";
import partnerElephant from "../../assets/partnerElephant.svg";
import partnerImg2 from "../../assets/partnerImg2.svg";
import partnerImg3 from "../../assets/partnerImg1.svg";

function partners() {
  return (
    <div className="pl-8 sm:pl-16 pr-8 sm:pr-16 h-full sm:h-[75vh]">
      <Grid container item spacing={0} marginBottom={"3em"}>
        <Grid item xs={5.8}></Grid>
        <Grid item xs={6.2} className="flex justify-end">
          <div className="titleStylesPartner">
            <div className="transparentBold"></div>PART
          </div>
          <div className="titleStylesPartner">
            <div className="redBoldPartner"></div>N
          </div>
          <div className="titleStylesPartner">
            <div className="transparentBold"></div>ERS
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={0}>
          <Grid container item xs={12} spacing={0} className="pt-8 flex flex-col sm:flex-row gap-8 sm:gap-0 justify-center">
          <Grid item md={4} xs={12} className="flex justify-center w-fit">
              <img src={partnerImg2} alt="partner img 3" className="h-40" />
            </Grid>
            <Grid item md={4} xs={12} className="flex justify-center w-fit">
              <img src={partnerElephant} alt="partner img 2" />
            </Grid>
            
            <Grid item md={4} xs={12} className="flex justify-center w-fit">
              <img src={partnerImg3} alt="partner img 3"  />
            </Grid>
          </Grid>
      </Grid>
    </div>
  );
}

export default partners;
