import React from "react";
import { Card, Typography, Grid } from "@mui/material";
import "./index.css";
import footerIcon from "../../assets/footerLogo.png";


function Footer() {
  return (
    // <div className="footer">
    //   <Grid className="footerBottomTitleContainer p-2">
    //     <p className="text-center">
    //     Copyrights © 2023 Social innovation lab - Sri Lanka. All Rights Reserved. Designed by:OriginDesk

    //     </p>
    //   </Grid>
    // </div>
    <div className="flex w-6 z-0">
      <p className="text-xs text-center relative left-32 -rotate-90 origin-top-right translate-y-16 -translate-x-[55em] sm:-translate-x-[47em] whitespace-nowrap z-0">
        Copyrights © 2023 Social innovation lab - Sri Lanka. All Rights Reserved. Designed by: OriginDesk
      </p>
    </div>
  );
}

export default Footer;
