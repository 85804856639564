import React from "react";
import Header from "../../Layout/header";
import Footer from "../../Layout/footer";
import Publications from "../../components/publications";

function index() {
  return (
    <div>
      <Header />
      <Publications />
      <Footer />
    </div>
  );
}

export default index;
