import React, { useState } from "react";
import "./projects.css";
import { Grid } from "@mui/material";
import Accordion from "./Accordion";

function Projects() {

  const[isActive,setIsActive]=useState(false);

  const handleClick = (index)=>{
    if(index===null){
      setIsActive(false)
    }else{
      setIsActive(true)
    }
  }

  return (
      <div className="pl-8 sm:pl-16 pr-8 sm:pr-16 w-full">
        <div className={`${isActive?'hide':''}`}>
          <Grid container spacing={0} className='mb-4'>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} className="flex justify-end">
              <div className="titleStylesProject">
                <div className="transparentBold"></div>PRO
              </div>
              <div className="titleStylesProject">
                <div className="redBoldProject"></div>J
              </div>
              <div className="titleStylesProject">
                <div className="transparentBold"></div>ECTS
              </div>
            </Grid>
          </Grid>
        </div>
        <Accordion onClick={handleClick} isOpen={isActive}/>      
      
    </div>
  );
}

export default Projects;
