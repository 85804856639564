import React from "react";
import "./publications.css";
import faceIconTile from "../../assets/faceIconTile.png";
import handTile from "../../assets/handTile.png";
import bicycleTile from "../../assets/bicycleTile.png";
import elephantEyeTile from "../../assets/elephantEyeTile.png";
import { Card, Typography, Grid } from "@mui/material";

function publications() {
  return (
    <div>
      <div className="container">
        <Grid container spacing={0}>
          <Grid item xs={3.0}></Grid>
          <Grid item xs={6.2} className="titleContainer">
            <div className="titlePub">
              <div className="transparentBold"></div>PUBL
            </div>
            <div className="titlePub">
              <div className="redBoldPub"></div>I
            </div>
            <div className="titlePub">
              <div className="transparentBold"></div>CAT
            </div>
            <div className="titlePub">
              <div className="redBoldPub"></div>I
            </div>
            <div className="titlePub">
              <div className="transparentBold"></div>ONS
            </div>
          </Grid>
        </Grid>
      </div>{" "}
      <div class="outerContainer" style={{ marginTop: "-24vw" }}>
        <div className="tileConainer">
          <div className="rowContainer">
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
          </div>
          <div className="rowContainer">
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
          </div>
          <div className="rowContainer">
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
          </div>
          <div className="rowContainer">
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
            <div className="style1"></div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style3"></div>
            <div>
              <img src={faceIconTile} className="tileImg" />
            </div>
            <div className="style1"></div>
          </div>
          <div className="rowContainer">
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
            <div className="style4"></div>
            <div>
              <img src={handTile} className="tileImg" />
            </div>
            <div className="style5">Lorem Ipsum</div>
            <div>
              <img src={bicycleTile} className="tileImg" />
            </div>{" "}
            <div className="style4"></div>
          </div>
          <div className="rowContainer">
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
            <div className="style6"></div>
            <div className="style3"></div>
            <div>
              <img src={elephantEyeTile} className="tileImg" />
            </div>
            <div className="style2">Lorem Ipsum</div>
            <div className="style6"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default publications;
